<template>
  <svg
    width="36"
    height="36"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M18 18H2.25L10.125 4.5H25.875L18 18Z" fill="#79C0FF" />
    <path d="M25.875 31.5H10.125L18 18H33.75L25.875 31.5Z" fill="#0E419D" />
    <path d="M10.125 31.5L2.25 18H18L10.125 31.5Z" fill="#388BFD" />
    <path d="M25.875 4.5L33.75 18H18L25.875 4.5Z" fill="#1F6FEB" />
  </svg>
</template>
